@import "../../../styles";

.newforma-translatedDatePickerComponent {
  .newforma-flexColumn;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: flex-end;
  vertical-align: bottom;
  width: 100%;

  .newforma-datePicker {
    width: 100%;
  }

  .ms-Label {
    color: @text-light-color!important;
 
    &:after{
      color: @text-light-color!important;
    }
  }

  .newforma-dateClear {
    color: @primary-500;
    font-size: @font-size-default;
    cursor: pointer;
    padding-top: @spacing-small;
  }
}