@import "../../../styles";

.newforma-settingsComponent-back {
  color: @text-label-color;
  font-size: @font-size-medium;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
  font-weight: 600;
}

.newforma-settingsComponent-title {
  color: @color-title-black;
  font-size: @line-height-medium-small;
  text-align: left;
  padding: @spacing-medium @spacing-none @spacing-big @spacing-none;
}

.newforma-settingsComponent-box {
  box-sizing: border-box;
  border: @border-box;
  border-radius: @border-radius-scrollbar;
  background-color: @background-white-color;
  margin-bottom: @spacing-big;
}

.newforma-settingsComponent-box-container {
  display: flex;
  padding: @spacing-medium 21px @spacing-none 21px;
  justify-content: space-between;
}

.newforma-settingsComponent-box-title {
  text-align: left;
  margin-bottom: @spacing-medium;
  font-size: @font-size-big;
  font-weight: 600;
}

.newforma-settingsComponent-box-text {
  color: @text-color-settings;
  font-size: 13px;
  padding: 0px 21px;
  text-align: left;
  margin-bottom: @spacing-large;
}

.newforma-settingsComponent-footer-text {
  color: #909090;
  font-size: 13px;
  text-align: left;
  margin-bottom: @spacing-small;
}

.newforma-settingsComponent-button {
  margin: @spacing-big @spacing-big;
}

.newforma-settingsPanel {
  .newforma-flexColumn;

  .ms-Panel-content {
    margin: @spacing-medium @spacing-large;
  }

  .newforma-settingsComponent {
    .newforma-flexColumn;
    min-width: @width-medium;
    text-align: center;
  }

  .newforma-settingsFooter {
    text-align: center;
  }

  .ms-Toggle-label {
    margin-left: @spacing-medium-small;
    text-align: left;
    -ms-word-break: break-word;
    word-break: break-word;
  }
}

.diagnostic-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.diagnostic-button {
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgb(0, 120, 212);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
}