@import "../../styles";

.ms-Label, .ms-Checkbox-text {
  font-size: @font-size-default;
  color: @text-light-color;
}

.ms-Dropdown-title,
.ms-TextField-fieldGroup,
.ms-BasePicker-text,
.ms-TextField-field {
  border-color: @border-color !important;
  color: @text-medium-color !important;
}

.ms-Dropdown.is-disabled .ms-Dropdown-titleIsPlaceHolder{
  color: @text-disabled !important;
  border: 1px solid @border-color !important;
}

.ms-PickerPersona-container {
  min-width: @dimension-none;
  max-width: @width-big;
}
