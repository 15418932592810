@import "../../styles";

.submittalComponent {
  width: 100%;
  min-height: @dimension-none;
  overflow-y: auto;
  overflow-x: hidden;

  .submittalRevisionNumber {
    &.hidden {
      display: none;
    }
  }

  .submittalComponentForm {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rowSpacing{
    min-width: @dimension-none;
    width: 50%;
  }

  .fieldButton {
    display: flex;
    justify-content: end;
    color: @primary-500 !important;

    &.hidden {
      display: none;
    }
  }

  .submittal-noBottomPadding {
    padding-bottom: 0;
  }

  .submittalSpecSection {
    width: 100%;
    .ms-BasePicker-text {
      min-width: @dimension-none;
    }
    .ms-BasePicker-input  {
      min-width: @dimension-none;
      width: 100%;
      text-overflow: ellipsis;
    }
  }

  .dropdownSuggestedItem{
    margin: 0 @spacing-medium-big;
    padding: @spacing-medium-small 0 @spacing-medium-small 0;
    border-bottom: @border-light;
    text-align: left;
    width: 100%;
  }

  .submittalDatePickers {
    width: 100%;
    min-width: @dimension-none;
  }

  .submittalPurposeDropdown {
    .ms-Label {
      display: block;
    }

    .ms-Dropdown-titleIsPlaceHolder {
      color: @text-disabled !important;
    }

    .ms-Dropdown-title {
      :disabled {
        color: @text-disabled !important;
      }
    }

    ::after {
      color: @text-light-color !important;
    }
  }

  .ms-Label, .ms-Checkbox-text {
    font-size: @font-size-default;
    color: @text-light-color;
    white-space: nowrap;
  }

  .ms-TextField-fieldGroup,
  .ms-BasePicker-text,
  .ms-TextField-field {
    border-color: @border-color !important;
    color: @text-medium-color !important;
  }

  .ms-PickerPersona-container {
    min-width: @dimension-none;
    max-width: @width-big;
  }

  .flexRow {
    display: flex;
    gap: @spacing-medium;
    align-items: flex-end;
  }
}

.splitMenuPropStyle {
  width: 90%;
  text-align-last: center;
  -moz-text-align-last: center;
  -ms-text-align-last: center;

  .ms-Callout-beak {
    left: @spacing-split-button-beak-right !important;
  }
}