@import "../../../styles";

.supportedFilingOptions-unsubscribed {
  width: 100%;
  min-height: @dimension-none;
  text-align: center;
  margin: auto;

  .supportedFilingOptions-content {
    height: 100%;
    .supportedFilingOptions-title {
      font-size: @font-size-default;
      line-height: @line-height-medium;
      color: @text-login-color;
      font-weight: bold;
    }

    .supportedFilingOptions-message {
      font-size: @font-size-default;
      color: @text-light-medium-color;
      font-weight: 400;
    }
  }
}

.supportedFilingOptions-back {
    color: @text-label-color;
    font-size: @font-size-medium;
    font-weight: 300;
    text-align: left;
    cursor: pointer;
    font-weight: 600;
    margin-top: @spacing-medium;
    position: fixed;
  }

.spinner-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}