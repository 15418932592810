@import "../../styles";

.newforma-sendAndFileComponent {
  .newforma-flexColumn;
  min-height: @dimension-none;

  .newforma-sendAndFileContainer {
    min-width: @dimension-none;
    min-height: @dimension-none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }

  .newforma-headerHint{
    font-style: italic;
    color: @text-light-color;
    font-weight: @font-weight-medium;
    padding-left: @spacing-small;
  }
}

.newforma-send-and-file-header {
  color: @background-grey-color-two;
  font-size: @font-size-medium;
  font-weight: @font-weight-medium;
  margin: @spacing-medium-bigger @spacing-login-button @spacing-medium-bigger @spacing-login-button;
}

.info-container {
  display: flex;
  align-items: center;
}

.info-container-icon {
  font-size: @font-size-default;
  color: @background-grey-color-two;
  border: @border-info-container;
  border: 50%;
  border-radius: 50%;
  margin-top: @spacing-tiny;
  cursor: pointer;
}

.info-container-icon-hovercard {
  padding: @spacing-small;
  font-size: @font-size-default;
  color: @background-grey-color-two;;
}

.newforma-send-and-file-header-sub {
  color: @background-grey-color-two;;
  font-size: @font-size-default;
  font-weight: @font-weight-thin;
  margin: @spacing-none @spacing-small-two @spacing-none @spacing-login-button;
}

.newforma-send-and-file-submitButton {
  margin: 5px @spacing-login-button @spacing-none @spacing-login-button;
}

