@import "../../styles";

.newforma-loginPage {
  background-color: @background-white-color;
  .newforma-flexColumn;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;

  .newforma-loginContent {
    .newforma-flexColumn;
    align-items: center;
  }

  .newforma-welcomeLogo {
    padding-top: @padding-top-logo;
    padding-bottom: @spacing-medium-big;
    width: 68%;
    margin-top: @margin-top-logo;
  }

  .newforma-loginMarketingContainer {
    width: 240px;
    align-items: center;
    padding-bottom: @margin-bottom-container;

    label {
      font-size: @font-size-big;
      line-height: @line-height-medium-small;
      color: @text-login-color;
      text-align: center;
    }
  }

  .bimtrack-loginButton {
    width: 270px;
    height: fit-content;
    background: @background-light-color;
    border: 1px solid @background-grey-color;
    display: flex;
    align-items: center;
    margin-right: @spacing-login-button;
    margin-left: @spacing-login-button;

    img {
      padding: @spacing-medium @spacing-huge-plus @spacing-medium @spacing-big;
    }

    Label {
      color: @text-black-color;
      padding: @spacing-medium 0 @spacing-medium 0;
      cursor: pointer;
    }
  }

  .bimtrack-loginButton:hover {
    background-color: @background-grey-color;
    cursor: pointer;
  }

  .bimtrack-helpLink {
    padding-top: @spacing-great;
  }
}