@import "../../../styles";

.ms-Callout {
  width: 85%;
}

.pim-projectPickerComponent {
  margin: @margin-dropdown;
}

.pim-projectNameItem {
  width: 100%;
  margin: @spacing-none @spacing-medium-big;
  text-align: left;

  .ms-TagItem-TextOverflow {
    padding-left: @spacing-none;
    padding-right: $padding-left;
  }
}


.pim-projectHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pim-projectPicker {
  width: 100%;

  .pim-projectPlaceholder {
    text-overflow: ellipsis;
  }
}

.newforma-projectNameSuggestedItem:extend(.newforma-projectNameItem all) {
  .newforma-projectNameSuggestedItemValue {
    font-weight: @font-weight-less-bold;
    font-size: @font-size-medium;
    line-height: @spacing-bigger;
    color: @text-black-color;
  }
}

.newforma-lastProjectNameSuggestedItem:extend(.newforma-projectNameSuggestedItem all) {
  margin-bottom: @margin-bottom-item-component;
  border-bottom: @border-light;
  width: 100%;
}