@import "../../styles";

.newforma-fileMultipleEmailComponent {
  height: 100%;
  width: 100%;
  min-height: @dimension-none;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: @background-white-color;
}

.newforma-fileMultipleEmailComponentForm {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.newforma-emailRowSpacing {
  padding-bottom: @spacing-small;
}

.newforma-scrollableEmailList {
  overflow-y: auto;
  height: 100%;
}

.newforma-endOfListMessage {
  width: 100%;
  text-align: center;
  color: @text-footer-color;
  padding-top: @spacing-medium;
  padding-bottom: @spacing-footer-big;
  font-size: @font-size-default;
}

.newforma-multipleEmailListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.hidden {
    display: none;
  }

  .newforma-selectEmailHeader {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: @spacing-medium;
  }

  .newforma-folderLabel {
    margin-left: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: end;
    width: 50%;
  }
}

.newforma-searchBox {
  border-color: @border-color;
  margin-bottom: @spacing-medium-small;
}

.newforma-loadingEmailsIndicator {
  padding-bottom: @height-footer;
}

.email-body {
  margin: @spacing-none @line-height-medium;
  height: 100%;
}

.email-selection-label {
  color: @text-label-color;
}

.newforma-scrollableEmailList:hover .infinite-scroll-component::-webkit-scrollbar-thumb {
  display:contents;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: @background-scrollbar;
  display: none;
}

.newforma-infiniteScroll {
  mask-image: linear-gradient(to bottom, @background-white-color calc(100vh - 400px), transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, @background-white-color calc(100vh - 400px), transparent 100%);
}

.infinite-scroll-component::-webkit-scrollbar {
  width: @spacing-medium-small;
}

.infinite-scroll-component::-webkit-scrollbar-track {
  border-radius: @spacing-small;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  border-radius: @border-radius-scrollbar;
}
.infinite-scroll-component::-webkit-scrollbar-thumb:hover {
  background-color: @background-scrollbar-hover;
}

.newforma-noResultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
}