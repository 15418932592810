@import "../../styles";

.rfiComponent {
  width: 100%;
  min-height: @dimension-none;
  overflow-y: auto;
  overflow-x: hidden;

  .rfiSpecSectionField {
    .ms-BasePicker-text {
      min-width: @dimension-none;
    }

    .ms-BasePicker-input {
      min-width: @dimension-none;
      width: 100%;
      text-overflow: ellipsis;
    }
  }

  .rfiDatePickers {
    width: 100%;
    min-width: @dimension-none;
  }

  .rfiUrgentCheckbox {
    position: relative;
    float: right;
    top: @top-checkbox;
    height: 0;
    z-index: 999;
  }
}

.splitMenuPropStyle {
  width: 90%;
  text-align-last: center;
  -moz-text-align-last: center;
  -ms-text-align-last: center;

  .ms-Callout-beak {
    left: @spacing-split-button-beak-right !important;
  }
}