@import "../../styles";

.submittal-component-wrapper {
  padding: @spacing-none @line-height-medium;
  margin-bottom: @height-medium;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.tabs {
  width: 100%;
  margin: @spacing-medium @spacing-none @spacing-medium @spacing-none;
  display: flex;
}

.button-right {
  width: 50%;
  border: none;
  border-radius: @spacing-none @border-radius-capsule @border-radius-capsule @spacing-none;
}

.button-left {
  width: 50%;
  border: none;
  border-radius: @border-radius-capsule @spacing-none @spacing-none @border-radius-capsule;}

.button-unselected {
  color: @text-black-color;
  background: @background-light-color-less;
  -webkit-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @border-color;
  -moz-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @border-color;
  box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @border-color;
  white-space: nowrap;
}

.button-selected {
  background-color: @text-label-color;
  color: @text-white-color;
  fill: @text-white-color;
  font-weight: @font-weight-less-bold;
  white-space: nowrap;
}

.button-selected:hover {
  background-color: @text-label-color-hover;
  -webkit-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @text-label-color-hover;
  -moz-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @text-label-color-hover;
  box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @text-label-color-hover;
  color: @text-white-color;
  fill: @text-white-color;
}

.add-icon {
  font-weight: bolder;
  margin: @border-radius-capsule;
  font-size: @spacing-medium;
  padding-top: @spacing-tiny;
}

.file-icon {
  font-weight: bolder;
  margin: @spacing-small;
  font-size: @spacing-medium;
  padding-top: @spacing-tiny;
}