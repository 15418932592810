@import "../../../../styles";

.textFieldComponent textarea{
  resize: vertical;
}

.textFieldComponent .ms-TextField-field::-webkit-scrollbar-thumb {
  background-color: @background-scrollbar;
}

.textFieldComponent .ms-TextField-field::-webkit-scrollbar {
  width: @spacing-medium-small;
}

.textFieldComponent .ms-TextField-field::-webkit-scrollbar-track {
  border-radius: @spacing-small;
}

.textFieldComponent .ms-TextField-field::-webkit-scrollbar-thumb {
  border-radius: @border-radius-scrollbar;
}
.textFieldComponent .ms-TextField-field::-webkit-scrollbar-thumb:hover {
  background-color: @background-scrollbar-hover;
}