@import "../../../styles";

.itemSuccessComponentContainer{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  .itemIcon{
    margin-bottom: @spacing-large-big;
  }

  .itemErrorContainer{
    background-color: @gray-50;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: @spacing-large;
    gap: @spacing-big;
    box-sizing: border-box;
    border-radius: @border-radius-medium;
    margin: @error-container-margin;
    font-size: @font-size-medium;
  }

  .itemMessage{
    color: @color-title-black;
    font-weight: @font-weight-less-bold;
    text-align: center;
    margin-bottom: @spacing-medium-small;
  }

  .errorBody{
    display: flex;
    flex-direction: row;
    gap: @spacing-big;
  }

  .redirectLink{
    display: flex;
    color: @text-label-color;
    cursor: pointer;
    text-decoration: none;
    align-items: baseline;

    .redirectLinkIcon{
      margin-left: @spacing-medium-small;
      fill: @text-label-color;
    }
  }

  .newItem{
    font-size: @font-size-medium;
    text-decoration: none;
    position: fixed;
    bottom: 0;
    padding-bottom: 50px;
    display: flex;
  }
}