@import "../../../styles";

.newforma-fileEmailActions {

  display: flex;
  flex-direction: row;
  margin-left: auto;

  .ms-OverflowSet {
    column-gap: @spacing-small;
  }
  .ms-OverflowSet-item, .ms-OverflowSet-overflowButton {
    flex-shrink: inherit;
    .ms-Button {
      height: @height-avatar;
      width: @height-avatar;
      min-width: auto;
    }
    .ms-Button:hover, .ms-Button:active {
      border-radius: 50%;
    }

    .ms-Button:not(:hover){
      border-radius: 50%;
    }

    .ms-Button i {
      font-weight: 600;
    }
  }

}

.ms-Callout.newforma-projectEmailActionCallout{
  width: auto;

  .ms-ContextualMenu {
    min-width: auto;
  }
}
