@import "../../../../styles";

.contactDropdownSuggestedItem{
  margin: 0 @spacing-medium-big;
  padding: @spacing-medium-small 0 @spacing-medium-small 0;
  border-bottom: @border-light;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: grid;
}

.assigneeSuggestionsItem{
  margin: @spacing-small;
}