@import "../../../styles";

.dropdownSuggestedItem{
  margin: 0 @spacing-medium-big;
  padding: @spacing-medium-small 0 @spacing-medium-small 0;
  border-bottom: @border-light;
  text-align: left;
  width: 100%;
  overflow-wrap: anywhere;
}
