@import "../../styles";

.font-size-small {
  font-size: @font-size-small;
}

.issuecomponent-wrapper {
  padding: @spacing-none @line-height-medium;
  margin-bottom: @height-medium;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
