@import "../../../../styles";

.newforma-attachments {
  flex-direction: row;
  display: flex;
  align-items: baseline;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;

  .newforma-attachmentsAttachIconContainer {
    width: 32px;
    margin-right: 16px;
  }

  .newforma-attachmentsAttachIcon {
    margin-left: 10px;
    margin-right: 10px;
    color: #7f7f7f;
    font-size: large;
    transform: scale(-1, -1) rotate(45deg);
  }

  .newforma-attachmentsFilesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
  }

  .newforma-attachmentsExpandButton {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: end;
    min-width: 60px;
    i {
      margin-left: 10px;
    }
    span {
      width: 30px;
      text-align: right;
    }
  }

  button {
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    i {
      margin-right: 10px;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
}
