// this file is for global overrides and shared style classes
@import "../node_modules/office-ui-fabric-react/dist/css/fabric.min.css";
@import "../assets/styles/borders";
@import "../assets/styles/colors";
@import "../assets/styles/common";
@import "../assets/styles/dimensions";
@import "../assets/styles/font-styles";
@import "../assets/styles/margins";

* {
  margin: @spacing-none;
  padding: @spacing-none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: @spacing-none;
  padding: @spacing-none;
  font-family: Roboto, sans-serif;

  ::-ms-clear {
    display: none;
  }
}

a {
  color: @text-label-color;
  font-size: @font-size-default;
  font-family: "Segoe UI Semibold", serif;
  text-decoration: none;

  :link {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
  }
  :active {
    text-decoration: none;
  }
}

:root {
  // newforma colors
  --disabled-color: @background-disabled-color;
  --highlight-color: @background-important-action;
  --white-color: @text-white-color;
  --light-text-color: @text-light-color;
  --dark-text-color: @text-medium-color;
  --header-text-color: @text-accent-color;
  --link-color: @primary-500;
  --light-border-color: @border-color;
  --ms-required-red: @text-required-color;

  --header-height: @height-header;
  --footer-height: @height-footer;
}

.newforma-checkbox {
  padding-right: @spacing-small;
  flex-shrink: 0;
  width: @width-checkbox;
}

.newforma-appContainer {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.newforma-flexColumn {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex-basis: auto;

  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.newforma-flexRow {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.newforma-flexCentered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.newforma-appMain {
  .newforma-flexColumn;
}

.newforma-importantActionButton {
  width: @width-medium;
  margin-bottom: @spacing-huge;
  border: @border-none;
  padding: @spacing-none;
  color: @text-white-color;
  background-color: @background-important-action;

  :hover {
    background-color: @background-important-action;
    color: @text-white-color;
  }
}

.newforma-importantActionButton:disabled {
  background-color: @background-disabled-color  !important;
}

.newforma-flexHeader {
  width: 100%;
  background-color: @background-white-color;
}

.newforma-header {
  margin-left: @spacing-small;
  font-size: @font-size-nav;
  color: @text-accent-color;
  font-weight: bold;
  padding-bottom: @spacing-medium;
}

.newforma-SelectedEmailLabel {
  display: flex;
  flex-shrink: 0;
  padding: @spacing-none @spacing-small;
  font-weight: @font-weight-bold;
  font-size: @font-size-medium;
}

.newforma-messageBarContainer {
  position: fixed;
  top: 0;
  z-index: 999999;
  width: 100%;
  background: @background-white-color;

  .newforma-messageBar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    min-height: @height-medium;
    align-items: center;
    width: 100%;
    padding-left: @spacing-medium-big;

    .ms-MessageBar-content {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 92%;
    }
  }
}

.bimtrack-progress{
  z-index: 999;
  background-color: @background-blue-light-color;
  box-shadow: @spacing-none @spacing-small @spacing-small @spacing-none @box-shadow-color;
  padding: @padding-progress-bar;

  .bimtrack-progressIndicator{
    .ms-ProgressIndicator-itemName{
      font-size: @font-size-default;
      font-weight: @font-weight-bold;
    }
  }
}

.ms-DatePicker-callout{
  max-width: min-content;
}

.ms-Callout{
  .ms-Suggestions-container{
    max-height: 145px;
  }

  .ms-Suggestions-container::-webkit-scrollbar-thumb {
    background-color: @background-scrollbar;
  }

  .ms-Suggestions-container::-webkit-scrollbar {
    width: @spacing-medium-small;
  }

  .ms-Suggestions-container::-webkit-scrollbar-track {
    border-radius: @spacing-small;
  }

  .ms-Suggestions-container::-webkit-scrollbar-thumb {
    border-radius: @border-radius-scrollbar;
  }
  .ms-Suggestions-container::-webkit-scrollbar-thumb:hover {
    background-color: @background-scrollbar-hover;
  }
}

.ms-Callout-main,
.ms-Dropdown-callout {
  width: auto;

  .ms-Button-flexContainer {
    overflow: hidden;
    text-overflow: ellipsis;
    color: @text-medium-color  !important;
  }
}

.newforma-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  z-index: 999;
  position: fixed;
  width: 100%;
  height: @height-footer;
  text-align: center;
  background-color: @background-white-color;
  left: 0;
  right: 0;

  .newforma-footerButton {
    width: 90%;
  }

  div:first-of-type {
    width: 90%;

    :first-child {
      width: 100%;
    }
  }

  .ms-Icon {
    color: white;
  }
}

.newforma-formSpacing {
  padding-bottom: @spacing-medium;
}

.newforma-form {
  overflow-y: auto;
}

.newforma-progressIndicator {
  position: absolute;
  background-color: @background-white-color;
  width: 100%;
  bottom: @height-footer;
}

.withTopMargin{
  margin:  @spacing-medium-small 0 0 0
}

.withSurroundedMargin{
  margin: @margin-preview-button;
}

.bimtrack-preview-capsule-header-wrapper {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.smallIcon {
  width: @line-height-small;
  height: @line-height-small;
  padding-left: @spacing-small-two;
  margin-bottom: @spacing-small-minus-two;
}

.toastMessageLink {
  align-items: center;
  margin-left: @spacing-small-minus;
}

.toastMessageSeparator {
  height: @height-toast-separator;
  visibility: hidden;
}

.pimtrack-highlight {
  background-color: rgb(255, 228, 175);
  border-radius: 0.2rem;
  box-shadow: rgb(255, 228, 175) 0 0 0 0.1rem inset;
  border: none;
  padding: 0 0.2rem;
  font-style: normal;
}

body:hover ::-webkit-scrollbar-thumb {
  display:contents;
}

::-webkit-scrollbar-thumb {
  background-color: @background-scrollbar;
  display: none;
}

::-webkit-scrollbar {
  width: @spacing-medium-small;
}

::-webkit-scrollbar-track {
  border-radius: @spacing-small;
}

::-webkit-scrollbar-thumb {
  border-radius: @border-radius-scrollbar;
}

::-webkit-scrollbar-thumb:hover {
  background-color: @background-scrollbar-hover;
}