@import "../../styles";

.app-header-item {
  padding: @spacing-none @spacing-medium-small;
}
.app-header-item-large {
  padding: @spacing-medium-small;
}

.app-header-label {
  display: flex;
  align-items: center;
  font-size: @font-size-big;
  font-weight: @font-weight-less-bold;
}

.headerLabel-wrapper {
  display: flex;
  margin-top: @spacing-small;
  align-items: stretch;
  font-weight: @font-weight-less-bold;
  width: 100%;
  justify-content: space-between;
}

.bimone-projectNameItem{
  width: 100%;
  text-align: left;
}

.navHeader {
  font-size: @font-size-bigger !important;
  color: @text-label-color !important;
  font-weight: @font-weight-less-bold;

  .ms-Dropdown-title {
    border: @border-none;
    padding: @spacing-feature-capsule;
    font-size: @font-size-bigger !important;
    color: @text-label-color !important;
    font-weight: @font-weight-less-bold;
  }

  .ms-Dropdown-title:focus,
  .ms-Dropdown-title::after
   {
    border: @border-none;
    padding: @spacing-feature-capsule;
  }

  .ms-Dropdown:focus,
  .ms-Dropdown:focus::after {
    border: @border-none;
  }
}

.ms-OverflowSet-item {
  width: 100%;
}

.ms-TagItem-TextOverflow {
  max-width: @width-bigger;
  text-align: left;
}

.ms-Dropdown-item {
  min-width: @spacing-header;
}

.headerLabel-wrapper-with-feature {
  display: flex;
}

.font-size-big {
  font-size: @font-size-bigger;
}

.ms-Dropdown-caretDown {
  color: @text-label-color !important;
  font-size: @font-size-small;
  font-weight: @font-weight-bold;
}

.ms-Icon {
  color: @text-label-color;
}

.change-hubs-item-content {
  border: none;
  width: 100%;
  padding: @spacing-none @spacing-medium-small;
  
  .ms-Button-flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: @spacing-small;

    .change-hubs-item-content-logo {
      svg {
        height: @font-size-big;
        width: @spacing-large;
        margin-right: @spacing-medium-small;
        padding-top: @spacing-small;
      }
    }

    .change-hubs-item-switch-logo {
      font-weight: 700;

      svg {
        height: @font-size-small;
        width: @font-size-small;
        margin-right: @spacing-medium-small;
      }
    }

    .change-hubs-item-content-sub {
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;

      .change-hubs-item-content-label-sub {
        color: @text-label-color;
        font-size: @font-size-default;
      }
    }

    .change-hubs-item-content-sub.left {
      min-width: 0;
    }

    .change-hubs-item-content-sub.right {
      min-width: max-content;
    }

    .change-hubs-item-content-label {
      font-size: @font-size-medium;
      font-weight: 600;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
}
