@import "../../../styles";

.ms-Spinner {
  padding-left: 0px;
}

.newforma-emailListRowItemComponent {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  width: auto;
  padding: @spacing-medium-big @spacing-none;
  font-size: @font-size-default;
  border-bottom: @border-email-list-row;

  .ms-Checkbox-text {
    font-size: @font-size-default;
    color: @text-light-color;
  }

  .ms-Checkbox-checkbox {
    margin-right: @spacing-none;
  }

  .newforma-checkboxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: @spacing-medium;
  }

  .newforma-emailListRowDetails {
    width: 100%;
    height: @height-email-item;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .newforma-emailListRowDetailTopRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
    }

    .newforma-subject {
      color: @text-color-subject;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: @font-size-medium;
    }

    .newforma-preview {
      color: @text-light-color;
      font-size: @font-size-default;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .newforma-from {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @text-color-from;
      font-size: @font-size-medium;
    }

    .newforma-sentDate {
      color: @text-color-subject-preview;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.ms-Callout-main {
  min-width: @dimension-none;
}

.newforma-preview-wrapper {
  display: flex;
  justify-content: space-between;
  color: @text-color-subject-preview;
  font-size: @font-size-medium;

  .newforma-mailCheck {
    display: flex;
    font-size: @font-size-big;
    margin-top: auto;
    margin-left: @spacing-medium-big;
  }
}

.newforma-selectEmailHeader-checkbox {
  cursor: pointer;
}

.newforma-selectEmailHeader-checkbox-wrapper {
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.newforma-hintText {
  max-width: @width-hint;
  width: max-content;
  border-radius: @border-radius-hover;
}

.newforma-hintText span{
 display: block;
}