@import "../../styles";

.issueComponent {
  width: 100%;
  min-height: @dimension-none;
  overflow-y: auto;
  overflow-x: hidden;

  .issueComponentForm {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .issueDatePicker {
    width: 100%;
    min-width: @dimension-none;

    ::after {
      display: none;
    }
  }

  .ms-Label, .ms-Checkbox-text {
    font-size: @font-size-default;
    color: @text-light-color;
    white-space: nowrap;
  }

  .ms-TextField-fieldGroup,
  .ms-BasePicker-text,
  .ms-TextField-field {
    border-color: @border-color !important;
    color: @text-medium-color !important;
  }

  .ms-PickerPersona-container {
    min-width: @dimension-none;
    max-width: @width-big;
  }
}

.splitMenuPropStyle {
  width: 90%;
  text-align-last: center;
  -moz-text-align-last: center;
  -ms-text-align-last: center;

  .ms-Callout-beak {
    left: @spacing-split-button-beak-right !important;
  }
}