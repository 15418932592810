@import "../../styles";

.bimtrack-EmptyHubsComponent {
  width: 100%;
  min-height: @dimension-none;
  text-align: center;
  padding-top: @spacing-greatest;

  .bimtrack-emptyHubsContent {
    height: 100%;
    .bimtrack-emptyHubTitle {
      font-size: @spacing-bigger;
      line-height: @line-height-medium;
      color: @text-login-color;
      font-weight: bold;
    }

    .bimtrack-emptyHubMessage {
      font-size: @font-size-medium;
      line-height: @spacing-bigger;
      color: @text-light-medium-color;
      font-weight: 400;
    }

    .bimtrack-emptyHubLink {
      padding-top: @spacing-greater;

      .bimtrack-emptyHubLinkLabel {
        font-size: @font-size-medium;
        line-height: @spacing-bigger;
        text-decoration: none;
        color: @primary-500;
        font-weight: 300;
      }
    }
  }
}

.hub-selector-dashboard-body {
  display: flex;
  flex-direction: column;
  padding: @spacing-login-button @spacing-login-button @spacing-none @spacing-login-button;
  height: 100%;
  justify-content: space-between;

  .hub-selector-dashboard-contents {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    margin-bottom: @height-large;
    padding-right: @spacing-small;

    .hub-selector-dashboard-item-wrapper {
      width: 100%;
      min-height: 0;
      height: 100%;
      overflow-y: auto;
    }
  }
  .hub-selector-dashboard-header {
    font-size: @font-size-bigger;
    font-weight: 500;
    color: @text-label-color;
    margin: @spacing-medium-bigger @spacing-none @spacing-medium @spacing-none;
  }
  .hub-selector-dashboard-sub-text {
    color: @text-light-color;
    font-size: @font-size-default;
    font-weight: @font-weight-thin;
    margin: @spacing-small @spacing-none @line-height-big @spacing-none;
  }
}


.hub-selector-dashboard-item {
  border: @border-hub-selector-item ;
  border-radius: @border-radius-scrollbar;
  background-color: @background-color-hub-selector;
  margin: @spacing-medium @spacing-none;
  padding: @line-height-small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: @spacing-huge-plus;
  cursor: pointer;

  .hub-selector-dashboard-item-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  .hub-selector-dashboard-title {
    width: @width-hub-item;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hub-selector-dashboard-arrow {
    font-size: @font-size-small;
  }
}

.hub-selector-dashboard-item:active {
  background-color: @background-color-hub-selector-active;
}

.hub-selector-dashboard-button-signout {
  border: none;
  color: @text-label-color;
  margin-bottom: @spacing-large;
  width: 100%;
  margin-left: @spacing-login-button;
  margin-right: @spacing-login-button;
}

.hub-selector-dashboard-image-wrapper {
  min-width: @spacing-huge-plus;
  max-width: @spacing-huge-plus;
  min-height: @spacing-huge-plus;
  max-height: @spacing-huge-plus;
  margin-right: @spacing-medium;

  .hub-selector-dashboard-image {
    width: 100%;
    height: 100%;
    border-radius: @border-radius-scrollbar;
    background-color: @background-white-color;
  }
}

.spinner-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}