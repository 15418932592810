@import "../../../../styles";

.specSectionComponent {
  width: 100%;

  .headerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .hintIcon {
      padding: @spacing-small;
      color: @primary-500;
      font-size: @font-size-medium;
    }
  }
}
