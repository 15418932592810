@import "../../../styles";

.projectEmail-emptyPageResult {
  width: 100%;
  min-height: @dimension-none;
  text-align: center;
  margin: auto;

  .projectEmail-emptyPageResult-content {
    height: 100%;
    .projectEmail-emptyPageResult-title {
      font-size: @font-size-default;
      line-height: @line-height-medium;
      color: @text-login-color;
      font-weight: bold;
    }

    .projectEmail-emptyPageResult-message {
      font-size: @font-size-default;
      color: @text-light-medium-color;
      font-weight: @font-weight-normal;
    }
  }
}