@import "../../../../styles";

.newforma-projectEmailViewerActions {

  display: flex;
  flex-direction: row;
  margin-left: auto;

  .ms-OverflowSet {
    column-gap: @spacing-medium-big;
  }
  .ms-OverflowSet-item, .ms-OverflowSet-overflowButton {
    flex-shrink: inherit;
    .ms-Button {
      height: @height-avatar;
      width: @height-avatar;
      min-width: auto;
    }
    .ms-Button:hover, .ms-Button:active {
      border-radius: 50%;
    }

    .ms-Button:not(:hover){
      border-radius: 50%;
    }

    .ms-Button i {
      font-weight: 300;
      font-size: @font-size-bigger;
    }
  }

}
