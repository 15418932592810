@import "../../../styles";
.newforma-emailsViewerComponent {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;
  background: #f5f5f5;
}

.newforma-emailsViewerComponentContainer {
  background: #fff;
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
}

.newforma-emailsViewerComponentBody {
  border-top: #dedede 1px solid;
  height: 100%;
  padding: 20px;
  overflow: hidden;

  * {
    // noinspection CssInvalidPropertyValue
    all: revert-layer;
  }
}

.newforma-emailsViewerComponentBody img {
  // Override the style in the email for images that overflow to fit container
  max-width: 100% !important;
  height: auto;
  display: block;
}

.newforma-emailsViewerComponentBody a {
  // Override link styles to avoid overflow in email body
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.newforma-tabsContainer {
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
  margin-bottom: 5px;
}

.newforma-tabItem {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: border-color 0.3s;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 150px;
}

.newforma-tabItem:hover {
  border-bottom: 3px solid grey;
}

.newforma-tabItem.selected {
  border-bottom: 3px solid #0F6CBD;
}

.newforma-tabText {
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  width: 140px;
  flex: 1;
  min-width: 0;
}

.newforma-removeIcon {
  margin-left: auto;
  cursor: pointer;
  width: 0;
  visibility: hidden;
}

.newforma-tabItem:hover .newforma-removeIcon {
  visibility: visible;
}

.newforma-mailIcon {
  color: #0F6CBD;
}

.newforma-tooltipHost {
  display: inline-flex !important;
  flex: 1 1 auto;
  min-width: 0;
  gap: 1rem;
  max-width: fit-content;
}

.ms-Tooltip {
  // Override tooltip styling
  width: auto !important;
  max-width: 300px;
  padding: 4px 8px;
}

.ms-layer {
  // Override the span which is used to position the tooltip
  width: 0 !important;
  position: absolute !important; // will avoid shifting of the tabs
}