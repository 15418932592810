@import "../../../../styles";

.newforma-suggestedItemWithSubtextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: 0 @spacing-medium-big;
  padding: @spacing-medium-small 0;
  border-bottom: @border-light;

  .newforma-primaryText {
    font-weight: @font-weight-less-bold;
  }

  .newforma-subtext {
    color: @text-light-color;
    font-size: @font-size-default;
  }
}