@import "../../../../styles";

.newforma-metadataContainer {
  overflow: hidden;
  flex-direction: column;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
}

.newforma-metadataSubject {
  flex-direction: row;
  display: flex;
}

.newforma-metadata {
  flex-direction: row;
  display: flex;
}

.newforma-metadataDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.newforma-emailSubject {
  margin-bottom: 1rem;
  font-size: 17px;
  font-weight: bold;
}

.newforma-metadataFrom {
  font-weight: bold;
}

.newforma-metadataItem {
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
  font-size: 13px;
}

.newforma-metadataLabel {
  color: grey;
}
