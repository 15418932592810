@import "../../../../../styles";

.newforma-toField {
  display: flex;
  column-gap: 0.5rem;
}

.newforma-toField .newforma-andMoreSpan {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  :hover {
    cursor: pointer;
  }
}

.newforma-chevronIcon {
  font-size: 10px;
}