@import "../../../styles";

.newforma-attachmentsContainer {
  width: 100%;
}

.newforma-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @spacing-large;
  border-radius: @border-radius;
  border: @border-dotted;
  background-color: @background-light-color;
  color: @text-dropzone-color;
  outline: none;
  transition: border .24s ease-in-out;
  margin-bottom: @spacing-large;
  cursor: pointer;
}

.newforma-dropzoneHint {
  font-size: @font-size-default;
  color: @text-accent-color;
  width: 100%;
  text-align: center;
}

.newforma-uploadError {
  color: @text-error-color;
  width: 100%;
  font-size: @font-size-default;
  padding-bottom: @spacing-small;
  padding-left: @spacing-tiny;
}