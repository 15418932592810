@import "colors";

.newforma-attachmentSelected {
  background-color: @background-selected-color;
}

.width-client {
  width: @width-hub-item;
}

.width-browser {
  width: @width-hub-label;
}