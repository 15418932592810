@import "../../../styles";

.newforma-projectEmailListRowDetailsWithPointer:hover {
  cursor: pointer;
  background: @background-grey-light;
}

.newforma-projectEmailListItemComponent {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  width: auto;
  font-size: @font-size-default;
  border-bottom: @border-email-list-row;
  padding:  @spacing-medium-small @line-height-medium;

  .newforma-projectEmailListRowAvatar {
    .ms-Persona-initials {
      color: #616161;
      background-color: #EBEBEB;
      line-height: @height-avatar;
      height: @height-avatar;
      font-size: @font-size-default;
    }

    .ms-Persona-imageArea {
      height: @height-avatar;
      width: @height-avatar;
    }
  }

  .newforma-projectEmailListRowDetails {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    row-gap: @spacing-medium-small;

    .newforma-projectEmailListRowDetailTopRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
    }

    .newforma-subject {
      color: @text-color-subject;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: @font-size-medium;
    }

    .newforma-preview {
      color: @text-light-color;
      font-size: @font-size-default;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .newforma-from {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @text-color-from;
      font-size: @font-size-medium;
    }

    .newforma-sentDate {
      color: @text-color-subject-preview;
      font-size: @font-size-default;
      line-height: @height-email-sent-date;
    }

    .newforma-preview-wrapper {
      display: flex;
      justify-content: space-between;
      color: @text-color-subject-preview;
      font-size: @font-size-medium;
    }
  }
}

.overflowItemsWrapper {
  padding: @spacing-none @spacing-medium-big;

  .overflowItem-icon {
    font-size: small;
    font-weight: 700;
    margin: 0;
  }
}

.extra-padding-top {
  padding-top: @spacing-medium-small;
}

.extra-padding-bottom {
  padding-bottom: @spacing-medium-small;
}