@import "../../styles";

.newforma-projectEmailComponent {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: @dimension-none;
  overflow: auto;
  background-color: @background-white-color;

  .newforma-endInfiniteScrollingMessage {
    width: 100%;
    text-align: center;
    color: @text-footer-color;
    padding-top: @spacing-medium;
    padding-bottom: @spacing-medium;
    font-size: @font-size-default;
  }
}
