@import "../../../../styles";

.newforma-attachmentComponent {
  &:last-child{
    border: none
  }

  .newforma-flexRow;
  flex-basis: auto;
  width: auto;
  padding: @spacing-medium-big @spacing-medium-small;
  border-bottom: @border-light;
  border-radius: @border-radius;
  margin-bottom: @margin-bottom-item-component;
  font-size: @font-size-default;
  align-items: center;

  .newforma-attachmentName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 2;
  }

  .newforma-attachmentExtension {
    flex-shrink: 0;
  }

  .newforma-attachmentCheckbox {
    flex-shrink: 0;
    width: @width-checkbox;
  }

  .newforma-attachmentCheckbox,
  .newforma-aiAttachmentIcon {
    padding-right: @spacing-small;
  }
}