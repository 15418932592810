@import "../../../styles";

.attachment-icon-container {
  height: @height-attachment-icon;
  width: @height-attachment-icon;
  background-color: @background-grey-light;
  border-radius: @border-radius-medium;

  & > i {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    font-size: @font-size-big;
    font-weight: @font-weight-medium;
  }
}