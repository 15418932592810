@import "../../../styles.less";

.newforma-searchFiltersBox {
    display: flex;
    margin: @spacing-search-input;
    box-sizing: border-box;
    gap: @spacing-medium;

    .newforma-searchBox {
        flex: 1;
        width: 0;
    }

    .newforma-search-filters-button {
        flex: 0;
    }

    .ms-layer {
        margin-right: -@spacing-medium;
    }
}
