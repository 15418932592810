@import "../../../../styles.less";

.newforma-search-filters-button {
    border-color: @border-color;

    &.filters-applied {
        color: @text-label-color;
    }

    &:not(.filters-applied) {
        color: @text-default-color;

        .ms-Button-icon {
            color: @text-default-color;
        }
    }
}

.filters-modal {
    width: 90%;
    padding: @spacing-large (@spacing-large / 2) @spacing-large @spacing-large;

    header,
    footer {
        background-color: @background-white-color;
        position: sticky;
        z-index: 1;
    }

    header {
        top: 0;
    }

    footer {
        padding-top: @spacing-large-big;
        bottom: 0;
    }

    section {
        flex: 1;
        overflow-y: auto;
    }

    .filters-modal-scrollable {
        padding-right: (@spacing-large / 2);
    }

    .ms-PickerPersona-container {
        max-width: @width-medium;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            text-align: left;
            font-size: @font-size-big;
            font-weight: @font-weight-less-bold;
        }
    }

    .formSpacing {
        padding-bottom: @spacing-large;
    }

    .received-date {
        display: flex;
        gap: @spacing-medium;

        .flex-child {
            flex: 1;
        }
    }

    .attachments {
        padding-top: @spacing-small;
        padding-bottom: @spacing-small;
    }

    .buttons {
        display: flex;
        gap: @spacing-medium;
        padding-bottom: @spacing-small;

        .button {
            border: none;
            flex: 1;
            width: 50%;
        }
    }

    .button-selected {
        background-color: @text-label-color;
        color: @text-white-color;
        fill: @text-white-color;
        font-weight: @font-weight-less-bold;
        white-space: nowrap;
    }

    .button-selected:hover {
        background-color: @text-label-color-hover;
        -webkit-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @text-label-color-hover;
        -moz-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @text-label-color-hover;
        box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @text-label-color-hover;
        color: @text-white-color;
        fill: @text-white-color;
    }

    .button-unselected {
        color: @text-black-color;
        background: @background-light-color-less;
        -webkit-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @border-color;
        -moz-box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @border-color;
        box-shadow: inset @spacing-none @spacing-none @border-radius-capsule @border-color;
        white-space: nowrap;
    }

    .button-unselected:hover {
        background-color: @background-disabled-color;
    }

    // the following 3 properties are required to fix
    // the 2 scrollbars issue on small height screens
    // https://github.com/microsoft/fluentui/issues/12420#issuecomment-976635149
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}
