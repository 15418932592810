@import "../../styles";

.rfiReviewResponseComponent{
  width: 100%;
  min-height: @dimension-none;
  overflow-y: auto;
  overflow-x: hidden;

  .ccButton{
    position: absolute;
    right: @spacing-none;
    cursor: pointer;
    z-index: 999;
    color: @primary-500;
    bottom: -@spacing-medium-small;
  }

  .rfiToFiedRow{
    position: relative;
  }
}